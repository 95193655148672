import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import TitanCard from "../TitanCard";
import "./styles.scss";
import TitanHelmet from "../../../images/titan-helmet.svg";

const TitanGrid = () => {
	const data = useStaticQuery(graphql`
		query {
			contentfulTitanGrid {
				titans {
					name
					title
					photo {
						gatsbyImageData(
							layout: CONSTRAINED
							placeholder: DOMINANT_COLOR
							formats: [AUTO, WEBP]
							quality: 100
							aspectRatio: 1
							width: 367
							height: 367
						)
						title
					}
					funPhoto {
						gatsbyImageData(
							layout: CONSTRAINED
							placeholder: DOMINANT_COLOR
							formats: [AUTO, WEBP]
							quality: 100
							aspectRatio: 1
							width: 367
							height: 367
						)
						title
					}
					blurb {
						childMarkdownRemark {
							html
						}
					}
					linkedInUrl
				}
			}
		}
	`);

	const {
		contentfulTitanGrid: { titans },
	} = data;

	return (
		<section className="section section--is-gray section--is-spacious">
			<div className="container">
				<div className="titan-helmet-image">
					<img src={TitanHelmet} alt="" aria-hidden="true" />
				</div>
				<h2 className="text text-center text--weight-bold text--xxxlarge">
					Team Titan
				</h2>
				<div className="titan-grid">
					{titans.map(titan => {
						const {
							name,
							title,
							photo,
							funPhoto,
							blurb,
							linkedInUrl,
						} = titan;

						return (
							<TitanCard
								key={name}
								name={name}
								photo={photo}
								funPhoto={funPhoto}
								title={title}
								blurb={blurb}
								linkedInUrl={linkedInUrl}
							/>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default TitanGrid;
