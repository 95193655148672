import React from "react";
import Layout from "../../components/layout";
import SearchEngineOptimization from "../../components/SearchEngineOptimization";
import TitanGrid from "../../components/Culture/TitanGrid";
import Potis from "../../components/Culture/Potis";
import JobPostingCarousel from "../../components/JobPostingCarousel";
import Polygon from "./polygon.svg";
import "./styles.scss";

function CulturePage() {
	
	return (
		<Layout>
			<SearchEngineOptimization title="Culture" />
			<div className="culture">
				<section className="section section--is-spacious">
					<div className="container">
						<h1 className="culture__heading">
						<div className="culture__heading-text">Our Culture</div>
							<div className="culture__polygon">
								<img src={Polygon} alt="" aria-hidden="true" />
							</div>
						</h1>
					</div>
					<div className="container container--is-narrow">
						<div className="markdown">
							<p>We like to say 'company culture is what happens between the meetings'. So there's a lot we could write about what makes our culture, maybe it's flying beef sticks at our All-Titan weekly meetings, flexible schedule or unlimited PTO policy. Rope-ladder-rooftop-happy-hours, custom hot sauces and whiskey blending, a STRONG culture of making fun of Darin, a weird love of KISS, or a dress code policy that starts and ends with 'absolutely no sweatpant shorts'. </p>
							<p>The rule that we follow for corporate culture is <b> "We take our work seriously but we refuse to take ourselves seriously."</b></p>
							<p>Every Titan must represent these 5 traits in their own unique way.</p>
						</div>
					</div>
				</section>
				<Potis />
				<TitanGrid />
				<JobPostingCarousel
					heading="Current Openings"
					subHeading="Are you ready to be a Titan?"
					description="We're always looking for team members who demonstrate POTIS and a drive to make ecommerce green."
					jobPostingNoJobs="We're all suited up right now. If you're sure you're a Titan in waiting, send us a message. We still want to talk with you."
				/>
			</div>
		</Layout>
	);
}

export default CulturePage;
