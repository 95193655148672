import React, { useState } from "react";
import "./styles.scss";

const Potis = () => {
	const [selectedTrait, setSelectedTrait] = useState("passion");
	
	const handleTraitChange = trait => {
		setSelectedTrait(trait);
	};

	return (
		<div className="potis">
			<section className="section section--is-dark section--is-paddingless">
				<div className="container">
					<div className="potis__traits">
						<button
							onClick={() => handleTraitChange("passion")}
							className={`potis__trait-button ${
								selectedTrait === "passion"
									? "potis__trait-button--active"
									: ""
							}`}
						>
							<div className="potis__trait-letter" aria-hidden="true">
								P
							</div>
							<div className="potis__trait">Passion</div>
						</button>
						<button
							onClick={() => handleTraitChange("ownership")}
							className={`potis__trait-button ${
								selectedTrait === "ownership"
									? "potis__trait-button--active"
									: ""
							}`}
						>
							<div className="potis__trait-letter" aria-hidden="true">
								O
							</div>
							<div className="potis__trait">Ownership</div>
						</button>
						<button
							onClick={() => handleTraitChange("teamwork")}
							className={`potis__trait-button ${
								selectedTrait === "teamwork"
									? "potis__trait-button--active"
									: ""
							}`}
						>
							<div className="potis__trait-letter" aria-hidden="true">
								T
							</div>
							<div className="potis__trait">Teamwork</div>
						</button>
						<button
							onClick={() => handleTraitChange("impact")}
							className={`potis__trait-button ${
								selectedTrait === "impact"
									? "potis__trait-button--active"
									: ""
							}`}
						>
							<div className="potis__trait-letter" aria-hidden="true">
								I
							</div>
							<div className="potis__trait">Impact</div>
						</button>
						<button
							onClick={() => handleTraitChange("skills")}
							className={`potis__trait-button ${
								selectedTrait === "skills"
									? "potis__trait-button--active"
									: ""
							}`}
						>
							<div className="potis__trait-letter" aria-hidden="true">
								S
							</div>
							<div className="potis__trait">Skills</div>
						</button>
					</div>
				</div>
			</section>
			<section className="section section--is-paddingless">
				<div className="container">
					<div className="potis__trait-description">
						{selectedTrait === "passion" && <div>On fire from the inside.</div>}
						{selectedTrait === "ownership" && <div>Carry your own bag.</div>}
						{selectedTrait === "teamwork" && <div>If you want to go far, go together.</div>}
						{selectedTrait === "impact" && <div>Run the ball north and south.</div>}
						{selectedTrait === "skills" && <div>No amateurs allowed.</div>}
					</div>
				</div>
			</section>
		</div>
	);
};

export default Potis;
