import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const JobPostingCard = ({ title, excerpt, bambooJobLink }) => {
	return (
		<a target="_blank" rel="noreferrer" href={bambooJobLink} className="job-posting-card">
			<div className="job-posting-card__info">
				<div className="job-posting-card__title">{title}</div>
				<p className="job-posting-card__excerpt">{excerpt}</p>
			</div>
		</a>
	);
};

export default JobPostingCard;

JobPostingCard.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	bambooJobLink: PropTypes.string,
};
