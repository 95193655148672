import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import TitanModal from "../TitanModal";
import LinkedInIcon from "../../../images/icons/linkedin.svg";
import "./styles.scss";

const TitanCard = ({
	photo,
	funPhoto,
	name,
	title,
	blurb,
	linkedInUrl,
}) => {
	const titanImageData = getImage(photo);
	const titanFunImageData = getImage(funPhoto);

	const [showModal, setShowModal] = useState(false);
	const [showFunPhoto, setShowFunPhoto] = useState(false);

	const handleOpenModal = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	return (
		<>
			<button
				className="titan"
				onClick={handleOpenModal}
				onMouseOver={() => setShowFunPhoto(true)}
				onMouseOut={() => setShowFunPhoto(false)}
				onFocus={() => setShowFunPhoto(true)}
				onBlur={() => setShowFunPhoto(false)}
			>
				<div className="titan__image">
					{titanFunImageData && (
						<div
							className={
								showFunPhoto && titanFunImageData
									? "titan__image-shown"
									: "titan__image-hidden"
							}
						>
							<GatsbyImage
								image={titanFunImageData}
								alt={photo.description ? photo.description : photo.title}
								loading="eager"
							/>
						</div>
					)}
					<div
						className={
							!showFunPhoto || !titanFunImageData
								? "titan__image-shown"
								: "titan__image-hidden"
						}
					>
						<GatsbyImage
							image={titanImageData}
							alt={photo.description ? photo.description : photo.title}
						/>
					</div>
					{linkedInUrl && (
						<a
							rel="noreferrer"
							className="titan__linkedin"
							target="_blank"
							href={linkedInUrl}
						>
							<img src={LinkedInIcon} alt={`${name} LinkedIn Profile`} />
						</a>
					)}
				</div>
				<div className="titan__name">{name}</div>
				<div className="titan__title">{title}</div>
			</button>
			<TitanModal
				name={name}
				title={title}
				linkedInUrl={linkedInUrl}
				photo={photo}
				blurb={blurb}
				showModal={showModal}
				handleCloseModal={handleCloseModal}
			/>
		</>
	);
};

export default TitanCard;

TitanCard.propTypes = {
	photo: PropTypes.shape({
		description: PropTypes.string,
		gatsbyImageData: PropTypes.object,
		title: PropTypes.string,
	}),
	funPhoto: PropTypes.shape({
		description: PropTypes.string,
		gatsbyImageData: PropTypes.object,
		title: PropTypes.string,
	}),
	name: PropTypes.string,
	title: PropTypes.string,
	handleOpenModal: PropTypes.func,
};
