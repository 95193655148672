import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import SwiperCore, { Navigation, A11y, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import "./styles.scss";
import JobPostingCard from "../JobPostingCard";
import { IoCaretForward, IoCaretBack } from "react-icons/io5";

SwiperCore.use([Navigation, A11y, Pagination]);

const JobPostingCarousel = ({ heading, subHeading, description }) => {
	const data = useStaticQuery(graphql`
		query {
			# Fix for error with type inference in GraphQL schema when there are no postings in Contentful.
			# One entry with "Dummy Content" title is held within Contentful
			allContentfulJobPosting(filter: { title: { ne: "Dummy Content" } }) {
				edges {
					node {
						contentful_id
						title
						jobPostingDate
						bambooJobLink
						excerpt {
							excerpt
						}
					}
				}
			}
		}
	`);

	const {
		allContentfulJobPosting: { edges: jobPostings },
	} = data;

	if (!jobPostings?.length) {
		return (
			<section className="section section--is-spacious">
				<div className="container">
					<div className="job-posting-carousel">
						<p className="job-posting-carousel__subheading">{subHeading}</p>
						<h2 className="job-posting-carousel__heading">{heading}</h2>
						<p className="job-posting-carousel__description">
							We're all suited up right now. If you're sure you're a Titan in
							waiting, send us a message. We still want to talk with you.
						</p>
					</div>
				</div>
			</section>
		);
	}

	return (
		<section className="section section--is-spacious">
			<div className="container">
				<div className="job-posting-carousel">
					{subHeading && (
						<p className="job-posting-carousel__subheading">{subHeading}</p>
					)}
					<h2 className="job-posting-carousel__heading">{heading}</h2>
					<p className="job-posting-carousel__description">{description}</p>
					<div className="job-posting-carousel__job-container">
						<button className="job-posting-carousel__nav-icon job-posting-carousel__nav-icon--prev swiper-button-prev">
							<IoCaretBack aria-hidden="true" size={60} />
							<span className="visually-hidden">Previous</span>
						</button>
						<Swiper
							spaceBetween={8}
							slidesPerView={1}
							freeMode={true}
							pagination={{ clickable: true }}
							navigation={{
								nextEl: ".swiper-button-next",
								prevEl: ".swiper-button-prev",
							}}
							breakpoints={{
								1024: {
									slidesPerView: 3,
									spaceBetween: 16,
								},
							}}
						>
							<div>
								{jobPostings.map(job => {
									const {
										contentful_id,
										title,
										excerpt: { excerpt },
										bambooJobLink,
									} = job.node;

									return (
										<SwiperSlide key={contentful_id}>
											<JobPostingCard
												title={title}
												excerpt={excerpt}
												bambooJobLink={bambooJobLink}
											/>
										</SwiperSlide>
									);
								})}
							</div>
						</Swiper>
						<button className="job-posting-carousel__nav-icon job-posting-carousel__nav-icon--next swiper-button-next">
							<span className="visually-hidden">Next</span>
							<IoCaretForward aria-hidden="true" size={60} />
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default JobPostingCarousel;

JobPostingCard.propTypes = {
	heading: PropTypes.string,
	subHeading: PropTypes.string,
	description: PropTypes.string,
};
