import React from "react";
import ReactModal from "react-modal";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { MdClose } from "react-icons/md";
import "./styles.scss";
import PolygonTitan from "./polygon-titan.svg";
import LinkedInIcon from "../../../images/icons/linkedin.svg";

const TitanModal = ({
	photo,
	name,
	title,
	blurb,
	linkedInUrl,
	handleCloseModal,
	showModal,
}) => {
	const titanModalImageData = getImage(photo);

	return (
		<ReactModal
			isOpen={showModal}
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}
			onRequestClose={handleCloseModal}
			contentLabel="Titan Modal"
			className="titan-modal-container"
			overlayClassName="titan-modal-overlay"
			ariaHideApp={false}
		>
			<div className="titan-modal">
				<button
					className="titan-modal__close-button"
					onClick={handleCloseModal}
				>
					<MdClose aria-hidden="true" size={40} />
					<span className="visually-hidden">Close Modal</span>
				</button>
				<div className="titan-modal__content">
					<div className="titan-modal__image">
						<GatsbyImage
							image={titanModalImageData}
							alt={
								photo.description
									? photo.description
									: photo.title
							}
						/>
						<div className="polygon titan-modal__polygon">
							<img
								className="polygon__image"
								src={PolygonTitan}
								alt=""
								aria-hidden="true"
							/>
						</div>
					</div>
					<div className="titan-modal__details">
						<div className="titan-modal__name">{name}</div>
						<div className="titan-modal__title">{title}</div>
						{linkedInUrl && (
							<a
								rel="noreferrer"
								className="titan-modal__linkedin"
								target="_blank"
								href={linkedInUrl}
							>
								<img src={LinkedInIcon} alt={`${name} LinkedIn Profile`} />
							</a>
						)}
						<div className="titan-modal__blurb">
							{blurb && (
								<div
									className="markdown"
									dangerouslySetInnerHTML={{
										__html: blurb.childMarkdownRemark.html,
									}}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</ReactModal>
	);
};

export default TitanModal;

TitanModal.propTypes = {
	photo: PropTypes.shape({
		description: PropTypes.string,
		gatsbyImageData: PropTypes.object,
		title: PropTypes.string,
	}),
	name: PropTypes.string,
	blurb: PropTypes.object,
	title: PropTypes.string,
	linkedInUrl: PropTypes.string,
	handleCloseModal: PropTypes.func,
	showModal: PropTypes.bool,
};
